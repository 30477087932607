*, *::before, *::after {
	box-sizing: border-box;
}
html {
	font-size: 10px;
	font-style: sans-serif;
}
@media (min-width: 38em) {
	html {
		font-size: 10px;
   }
}
@media (min-width: 52em) {
	html {
		font-size: 10px;
   }
}
@media (min-width: 72em) {
	html {
		font-size: 10px;
   }
}
@media (min-width: 90em) {
	html {
		font-size: 10px;
   }
}
body {
	background-color: #fff;
	font-family: 'Product Sans', 'Helvetica Neue', Arial, sans-serif;
}
.container {
	width: 100%;
	min-width: 18em;
	margin: 10px;
}
h1, h2 {
	text-align: left;
}
h1 {
	font-size: 2rem;
	font-weight: 700;
	margin-bottom: 0.5em;
}
h2 {
	font-size: 1.4rem;
	font-weight: 600;
	margin-bottom: 2em;
}
.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}
.tournament-bracket {
	display: flex;
	flex-direction: column;
}
@media (min-width: 38em) {
	.tournament-bracket {
		flex-direction: row;
   }
}
.tournament-bracket__round {
	display: block;
	margin-left: -3px;
	flex: 1;
	margin-right: -38px;
}
.tournament-bracket__round-title {
	color: #9e9e9e;
	font-size: 0.95rem;
	font-weight: 400;
	text-align: center;
	font-style: sans-serif;
	margin-bottom: 0.5em;
}
.tournament-bracket__list {
	display: flex;
	flex-direction: column;
	flex-flow: row wrap;
	justify-content: center;
	height: 100%;
	min-height: 100%;
	border-bottom: 1px dashed #e5e5e5;
	padding-bottom: 2em;
	margin-bottom: 2em;
	transition: padding 0.2s ease-in-out, margin 0.2s ease-in-out;
}
@media (max-width: 240em) {
	.tournament-bracket__list {
		padding-bottom: 1em;
		margin-bottom: 1em;
   }
}
@media (min-width: 38em) {
	.tournament-bracket__list {
		margin-bottom: 0;
		padding-bottom: 0;
		border-bottom: 0;
   }
}
.tournament-bracket__round:last-child .tournament-bracket__list {
	border: 0;
}
.tournament-bracket__item {
	display: flex;
	flex: 0 1 auto;
	justify-content: center;
	flex-direction: column;
	align-items: flex-start;
	position: relative;
	padding: 2% 0;
	width: 48%;
  
	transition: padding 0.2s linear;
}
.tournament-bracket__item:nth-child(odd) {
	margin-right: 2%;
}
.tournament-bracket__item:nth-child(even) {
	margin-left: 2%;
}
.tournament-bracket__item::after {
	transition: width 0.2s linear;
}
@media (max-width: 24em) {
	.tournament-bracket__item {
		width: 100%;
   }
	.tournament-bracket__item:nth-child(odd), .tournament-bracket__item:nth-child(even) {
		margin-left: 0;
		margin-right: 0;
   }
}
@media (min-width: 38em) {
	.tournament-bracket__item {
		padding: 0.5em 1em;
		width: 100%;
		
   }
	.tournament-bracket__item:nth-child(odd), .tournament-bracket__item:nth-child(even) {
		margin: 0;
   }
	.tournament-bracket__item::after {
		position: absolute;
		right: 0;
		content: '';
		display: block;
		width: 1em;
		height: 45%;
		border-right: 2px solid #9e9e9e;

   }
	.tournament-bracket__item:nth-child(odd)::after {
		top: 50%;
		border-top: 2px solid #9e9e9e;
		transform: translateY(-1px);
   }
	.tournament-bracket--rounded .tournament-bracket__item:nth-child(odd)::after {
		border-top-right-radius: 0.6em;
   }
	.tournament-bracket__item:nth-child(even)::after {
		bottom: 50%;
		border-bottom: 2px solid #9e9e9e;
		transform: translateY(1px);
   }
	.tournament-bracket--rounded .tournament-bracket__item:nth-child(even)::after {
		border-bottom-right-radius: 0.6em;
   }
	.tournament-bracket__round:first-child .tournament-bracket__item {
		padding-left: 0;
   }
	.tournament-bracket__round:last-child .tournament-bracket__item {
		padding-right: 0;
   }
	.tournament-bracket__round:last-child .tournament-bracket__item::after {
		display: none;
   }
	.tournament-bracket__round:nth-last-child(2) .tournament-bracket__item::after {
		border-radius: 0;
		border-right: 0;
   }
}
@media (min-width: 38em) {
	.tournament-bracket__item {
		padding: 0.5em 1.0em;
   }
	.tournament-bracket__item::after {
		width: 1.5em;
		height: 5.0em;
   }
}
@media (min-width: 52em) {
	.tournament-bracket__item {
		padding: 0.5em 1.0em;
   }
	.tournament-bracket__item::after {
		width: 1.5em;
		height: 5.0em;
   }
}
@media (min-width: 72em) {
	.tournament-bracket__item {
		padding: 0.5em 1.5em;
   }
	.tournament-bracket__item::after {
		width: 1.5em;
		height: 5.0em;
   }
}
@media (min-width: 90em) {
	.tournament-bracket__item {
		padding: 0.5em 2.0em;
   }
	.tournament-bracket__item::after {
		width: 1.5em;
		height: 6.0em;
   }
}
.tournament-bracket__match {
	display: flex;
	width: 100%;
	background-color: #f7f9ff;
	padding: 0.5em;
	border: 1px solid transparent;
	border-radius: 0.1em;
	box-shadow: 0 0 0 0 #f7f9ff;
	outline: none;
	cursor: pointer;
	transition: padding 0.2s ease-in-out, border 0.2s linear;
}
.tournament-bracket__match:focus {
	border-color: #2196f3;
}
.tournament-bracket__match::before, .tournament-bracket__match::after {
	transition: all 0.2s linear;
}
@media (max-width: 24em) {
	.tournament-bracket__match {
		padding: 0.75em 0.5em;
   }
}
@media (min-width: 38em) {
	.tournament-bracket__match::before, .tournament-bracket__match::after {
		position: absolute;
		left: 0;
		z-index: 1;
		content: '';
		display: block;
		width: 1em;
		height: 10%;
		border-left: 2px solid #9e9e9e;
   }
	.tournament-bracket__match::before {
		bottom: 50%;
		border-bottom: 2px solid #9e9e9e;
		transform: translate(0, 1px);
   }
	.tournament-bracket--rounded .tournament-bracket__match::before {
		border-bottom-left-radius: 0.6em;
   }
	.tournament-bracket__match::after {
		top: 50%;
		border-top: 2px solid #9e9e9e;
		transform: translate(0, -1px);
   }
	.tournament-bracket--rounded .tournament-bracket__match::after {
		border-top-left-radius: 0.6em;
   }
}
@media (min-width: 108em) {
	.tournament-bracket__match::before, .tournament-bracket__match::after {
		width: 1.5em;
   }
	.tournament-bracket__match::before {
		transform: translate(0, 1px);
   }
	.tournament-bracket__match::after {
		transform: translate(0, -1px);
   }
}
.tournament-bracket__round:last-child .tournament-bracket__match::before, .tournament-bracket__round:last-child .tournament-bracket__match::after {
	border-left: 0;
}
.tournament-bracket__round:last-child .tournament-bracket__match::before {
	border-bottom-left-radius: 0;
}
.tournament-bracket__round:last-child .tournament-bracket__match::after {
	display: none;
}
.tournament-bracket__round:first-child .tournament-bracket__match::before, .tournament-bracket__round:first-child .tournament-bracket__match::after {
	display: none;
}
.tournament-bracket__content {
	display: flex;
}
.tournament-bracket__content::after {
	content: ':';
	width: 1em;
	text-align: center;
	padding: 0.2em 0.1em;
}
@media (min-width: 38em) {
	.tournament-bracket__content::after {
		order: 1;
   }
}
.tournament-bracket__content .tournament-bracket__team:first-child {
	width: 50%;
	order: 0;
	text-align: right;
}
@media (min-width: 58em) and (max-width: 52em) {
	.tournament-bracket__content .tournament-bracket__team:first-child {
		align-items: flex-end;
   }
}
.tournament-bracket__content .tournament-bracket__team:first-child .tournament-bracket__country {
	order: 2;
	justify-content: flex-end;
}
@media (min-width: 24em) {
	.tournament-bracket__content .tournament-bracket__team:first-child .tournament-bracket__country {
		order: 0;
   }
}
@media (min-width: 38em) and (max-width: 52em) {
	.tournament-bracket__content .tournament-bracket__team:first-child .tournament-bracket__country {
		flex-direction: column-reverse;
		align-items: flex-end;
   }
}
.tournament-bracket__content .tournament-bracket__team:first-child .tournament-bracket__score {
	order: 0;
}
@media (min-width: 24em) {
	.tournament-bracket__content .tournament-bracket__team:first-child .tournament-bracket__score {
		order: 2;
   }
}
.tournament-bracket__content .tournament-bracket__team:last-child {
	width: 50%;
	order: 2;
	text-align: left;
}
@media (min-width: 38em) and (max-width: 52em) {
	.tournament-bracket__content .tournament-bracket__team:last-child {
		align-items: flex-start;
   }
}
@media (min-width: 38em) {
	.tournament-bracket__content .tournament-bracket__team:last-child .tournament-bracket__country {
		justify-content: flex-start;
   }
}
@media (min-width: 38em) and (max-width: 52em) {
	.tournament-bracket__content .tournament-bracket__team:last-child .tournament-bracket__country {
		align-items: flex-start;
   }
}
.tournament-bracket__content .tournament-bracket__team:last-child .tournament-bracket__code {
	order: 1;
}
.tournament-bracket__table {
	width: 100%;
}
.tournament-bracket__caption {
	font-size: 0.8rem;
	color: #bdbdbd;
	font-weight: 300;
	padding-bottom: 0.75em;
}
.tournament-bracket__team {
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;
}
@media (min-width: 24em) {
	.tournament-bracket__team {
		flex-direction: column-reverse;
   }
}
@media (min-width: 38em) {
	.tournament-bracket__team {
		flex-direction: column-reverse;
   }
}
.tournament-bracket__country {
	font-size: 0.95rem;
	display: flex;
	margin-top: 0.5em;
	align-items: center;
}
@media (max-width: 24em) {
	.tournament-bracket__country {
		margin-top: 0;
   }
}
@media (min-width: 38em) and (max-width: 52em) {
	.tournament-bracket__country {
		display: flex;
		flex-direction: column;
   }
	.tournament-bracket__country .tournament-bracket__code {
		margin-top: 0.2em;
   }
}
.tournament-bracket__code {
	padding: 0 0.5em;
	color: #212121;
	font-weight: 600;
	text-transform: uppercase;
	border: 0;
	text-decoration: none;
	cursor: help;
	transition: padding 0.2s ease-in-out;
}
@media (max-width: 24em) {
	.tournament-bracket__code {
		padding: 0 0.25em;
   }
}
@media (min-width: 38em) and (max-width: 52em) {
	.tournament-bracket__code {
		padding: 0;
   }
}
.tournament-bracket__score {
	display: flex;
	align-items: center;
}
.tournament-bracket__team:first-child .tournament-bracket__score {
	flex-direction: row-reverse;
	padding-left: 0.75em;
}
.tournament-bracket__team:last-child .tournament-bracket__score {
	padding-right: 0.75em;
}
.tournament-bracket__number {
	display: inline-block;
	padding: 0.2em 0.4em 0.2em;
	border-bottom: 0.075em solid transparent;
	font-size: 0.95rem;
	background-color: #f5f5f5;
	border-color: spin(shade(#f5f5f5, 10%), -10);
}
.tournament-bracket__team--winner .tournament-bracket__number {
	background-color: #fff176;
	border-color: spin(shade(#fff176, 2%), -10);
}
.tournament-bracket__medal {
	padding: 0 0.5em;
}
.tabs-nav li {
	float: left;
	width: 25%;
	display: block;
}
.tabs-nav li:first-child a {
	border-right: 0;
	border-top-left-radius: 6px;
}
.tabs-nav li:last-child a {
	border-top-right-radius: 6px;
}
a {
	background: #dce4f9;
	border: 1px solid #dce4f9;
	color: #849bc7;
	display: block;
	font-weight: 600;
	padding: 10px 0;
	text-align: center;
	text-decoration: none;
}
.tabActive {
	background-color: #0b214d;
	border-bottom-color: transparent;
	color: #fff !important;
	cursor: pointer;
}
.tab-active a {
	background: #dce4f9;
	border-bottom-color: transparent;
	color: #849bc7;
	cursor: pointer;
}
.tabs-stage {
	border-radius: 0 0 6px 6px;
	border-top: 0;
	clear: both;
	padding: 24px 30px;
	position: relative;
	top: -1px;
}
.text-center {
	text-align: center !important;
	color: #0b214d;
	font-weight: 500;
}
.hr-line {
	border-color: #dce4f9;
	margin-top: 0rem;
	margin-bottom: 1.1rem;
	border: 0;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.col-sm-6, .col-sm-3 {
	padding-right: 0px;
	padding-left: 0px;
}
.headingText {
	color: #01080c;
	text-align: center !important;
	font-weight: 600;
<<<<<<< HEAD
=======
	font-size: 16px;
	margin-top: 5px;
>>>>>>> NEWCODE
}
.regionText {
	color: #949ab1;
	text-align: center !important;
	font-weight: 500;
<<<<<<< HEAD
	font-size: 12px;
=======
	font-size: 14px;
>>>>>>> NEWCODE
	margin-bottom: -2px;
}
.ImagesText {
	display: flex;
}
.ImagePlayer {
	width: 25%;
	height: 35px;
}
.fonts {
	font-weight: 500;
	color: #01080c;
}
.dFonts {
	font-size: 12px;
	font-style: sans-serif;
}
.tabset {
	min-width: 65rem;
}
.tabset > input[type="radio"] {
	position: absolute;
	left: -200vw;
}
.tabset .tab-panel {
	display: none;
}
.tabset > label {
	position: relative;
	display: inline-block;
   margin-left:-2px;
   margin-right:-2px;
	padding: 15px 15px 15px;
	border-bottom: 0;
	background: #dce4f9;
	border-bottom-color: transparent;
	color: #849bc7;
	cursor: pointer;
	width: 22%;
	text-align: center;
	height: 50px;
	font-weight: 600;
}
.tabset > label::after {
	content: "";
	position: absolute;
	left: 15px;
	bottom: 10px;
	width: 22px;
	height: 4px;
	background: #dce4f9;
	border-bottom-color: transparent;
	color: #849bc7;
	cursor: pointer;
}
.tabset > input:checked + label {
	color: #fff;
	background-color: #01080c;
	border-color: #01080c #01080c #01080c;
	border-bottom: #01080c #01080c #01080c;
}
.tabset > input:first-child:checked ~ .tab-panels > .tab-panel:first-child, .tabset > input:nth-child(3):checked ~ .tab-panels > .tab-panel:nth-child(2), .tabset > input:nth-child(5):checked ~ .tab-panels > .tab-panel:nth-child(3), .tabset > input:nth-child(7):checked ~ .tab-panels > .tab-panel:nth-child(4), .tabset > input:nth-child(9):checked ~ .tab-panels > .tab-panel:nth-child(5), .tabset > input:nth-child(11):checked ~ .tab-panels > .tab-panel:nth-child(6) {
	display: block;
}
.tabset > input:focus + label {
	color: #fff;
	background-color: #01080c;
	border-color: #01080c #01080c #01080c;
}
.tabset > input:focus + label::after, .tabset > input:checked + label::after {
	color: #fff;
	background-color: #01080c;
	border-color: #01080c #01080c #01080c;
}
.tab-panel {
	padding: 30px 0;
}
*, *:before, *:after {
	box-sizing: border-box;
}

/* Winner 1 */
#hide {
 display: none;
}
label {
 cursor: pointer;
}
#hide:checked ~ #randomDiv {
 display: none;
}
#hide:checked ~ #showDIV {
display: block;
visibility: visible;
}
#showDIV
{
  display: none;
}
#randomDiv
{
 text-align: center !important;
 color:#0B214D;
 font-weight:500;
}

/*Hide Semi-Finalist4*/
#hidef1 {
 display: none;
}
label {
 cursor: pointer;
}
#hidef1:checked ~ #randomDivf1 {
 display: none;
}
#hidef1:checked ~ #showDIVf1 {
display: block;
visibility: visible;
}
#showDIVf1
{
  display: none;
}
#randomDivf1
{
 text-align: center !important;
 color:#0B214D;
 font-weight:500;
}


/*Hide Semi-Finalist4*/
#hidef2 {
 display: none;
}
label {
 cursor: pointer;
}
#hidef2:checked ~ #randomDivf2 {
 display: none;
}
#hidef2:checked ~ #showDIVf2 {
display: block;
visibility: visible;
}
#showDIVf2
{
  display: none;
}
#randomDivf2
{
 text-align: center !important;
 color:#0B214D;
 font-weight:500;
}




/*Hide Semi-Finalist4*/
#hidesf1 {
 display: none;
}
label {
 cursor: pointer;
}
#hidesf1:checked ~ #randomDivsf1 {
 display: none;
}
#hidesf1:checked ~ #showDIVsf1 {
display: block;
visibility: visible;
}
#showDIVsf1
{
  display: none;
}
#randomDivsf1
{
 text-align: center !important;
 color:#0B214D;
 font-weight:500;
}

/*Hide Semi-Finalist4*/
#hidesf2 {
 display: none;
}
label {
 cursor: pointer;
}
#hidesf2:checked ~ #randomDivsf2 {
 display: none;
}
#hidesf2:checked ~ #showDIVsf2 {
display: block;
visibility: visible;
}
#showDIVsf2
{
  display: none;
}
#randomDivsf2
{
 text-align: center !important;
 color:#0B214D;
 font-weight:500;
}


/*Hide Semi-Finalist4*/
#hidesf3 {
 display: none;
}
label {
 cursor: pointer;
}
#hidesf3:checked ~ #randomDivsf3 {
 display: none;
}
#hidesf3:checked ~ #showDIVsf3 {
display: block;
visibility: visible;
}
#showDIVsf3
{
  display: none;
}
#randomDivsf3
{
 text-align: center !important;
 color:#0B214D;
 font-weight:500;
}

/*Hide Semi-Finalist4*/
#hidesf4 {
 display: none;
}
label {
 cursor: pointer;
}
#hidesf4:checked ~ #randomDivsf4 {
 display: none;
}
#hidesf4:checked ~ #showDIVsf4 {
display: block;
visibility: visible;
}
#showDIVsf4
{
  display: none;
}
#randomDivsf4
{
 text-align: center !important;
 color:#0B214D;
 font-weight:500;
<<<<<<< HEAD
}
=======
}
>>>>>>> NEWCODE
